/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { listCategories } from 'services/category'
import { getChartData, listInputRecords, listOutputRecords } from 'services/charge'
import { listCustomers } from 'services/customer'
import { detailUser } from '../services/user'
import { messageError } from '../utils/toast'
import { buildQueryParams } from '../utils/queryBuilder'
import useApi from './useApi'
import useGlobal from './useGlobal'

function useRequestsProvider() {
  const { handleLogout } = useApi()
  const {
    token,
    setUser,
    setCategories,
    setCustomers,
    setInputRecords,
    setOutputRecords,
    setChartData,
    filterDate,
    filterPage,
    chargeType
  } = useGlobal()
  const [resetTables, setResetTables] = useState(false)

  async function handleGetUser() {
    try {
      const result = await detailUser(token)
      const { token: _, ...userData } = result
      setUser(userData)
    } catch (error) {
      messageError(error.response.data.message)
      setTimeout(() => {
        handleLogout()
      }, 2000)
    }
  }

  async function handleListCategories() {
    try {
      const result = await listCategories(token)
      setCategories(result)
    } catch (error) {
      messageError()
    }
  }

  async function handleListCustomers() {
    try {
      const result = await listCustomers(token)
      setCustomers(result)
    } catch (error) {
      messageError()
    }
  }

  async function handleCharts() {
    try {
      const query = buildQueryParams(filterDate)
      const chartData = await getChartData(token, query)
      setChartData(chartData)
    } catch (error) {
      messageError()
    }
  }

  async function handleListRecords() {
    try {
      const query = buildQueryParams({...filterDate, ...filterPage})
      if(chargeType === 'inputs') {
        const inputRecords = await listInputRecords(token, query)
        setInputRecords(inputRecords)
      } else if (chargeType === 'outputs') {
        const outputRecords = await listOutputRecords(token, query)
        setOutputRecords(outputRecords)
      }
    } catch (error) {
      messageError()
    }
  }

  async function handleUpdateTables() {
    try {
      await Promise.all([
        handleListCategories(),
        handleListCustomers(),
        handleListRecords()
      ])
    } catch (error) {
      messageError()
      setResetTables(false)
    } finally {
      setResetTables(false)
    }
  }

  async function handleUpdateCharts() {
    try {
      await handleCharts()
    } catch (error) {
      messageError()
    }
  }

  useEffect(() => {
    if (token || resetTables) {
      handleUpdateTables()
    }
  }, [
    token,
    resetTables,
    filterDate,
    filterPage
  ])

  useEffect(() => {
    if (token) {
      handleUpdateCharts()
    }
  }, [token, filterDate])

  return {
    handleGetUser,
    resetTables: () => setResetTables(true)
  }
}

export default useRequestsProvider
