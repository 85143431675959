import { useNavigate } from 'react-router-dom'
import api from '../services/api'
import useGlobal from './useGlobal'

function useApi() {
  const navigate = useNavigate()
  const { removeUser, removeToken } = useGlobal()

  function handleLogout() {
    removeUser()
    removeToken()
    navigate('/')
  }
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401) {
        handleLogout()
      }
      return Promise.reject(error)
    }
  )
  return {
    handleLogout
  }
}

export default useApi
