import { ReactComponent as CategoriesIcon } from 'assets/categories-icon.svg'
import { ReactComponent as ClientsIcon } from 'assets/clients-icon.svg'
import { ReactComponent as DashboardIcon } from 'assets/dashboard-icon.svg'

export const menuList = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
    active: true
  },
  {
    title: 'Clientes',
    icon: <ClientsIcon />,
    path: '/clientes',
    active: false
  },
  {
    title: 'Categorias',
    icon: <CategoriesIcon />,
    path: '/categorias',
    active: false
  }
]
