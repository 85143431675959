import { ReactComponent as ErrorIcon } from 'assets/error-icon.svg'
import { ReactComponent as SuccessIcon } from 'assets/success-icon.svg'
import { toast } from 'react-toastify'
import { theme } from 'theme/index'

export function messageSuccess(messageContent = 'Falha interna') {
  toast.success(messageContent, {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <SuccessIcon />,
    theme: 'light',
    style: {
      backgroundColor: '#EDF7ED',
      color: '#1E4620',
      ...theme.typography.body3
    }
  })
}

export function messageError(messageContent) {
  toast.error(messageContent, {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <ErrorIcon />,
    theme: 'light',
    style: {
      backgroundColor: '#FDEDED',
      color: 'rgba(95, 33, 32, 0.87)',
      ...theme.typography.body3
    }
  })
}
