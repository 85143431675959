import { createTheme } from '@mui/material'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1366,
      lg: 1440
    }
  },
  palette: {
    grey: {
      25: '#FCFCFD',
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#1D2939',
      900: '#101828'
    },
    primary: {
      25: '#EEF5FD',
      50: '#CCE1FA',
      100: '#AACEF7',
      200: '#89BAF4',
      300: '#67A6F0',
      400: '#4693ED',
      500: '#247FEA',
      600: '#196ACA',
      700: '#0F56A8',
      800: '#084184',
      900: '#032D5C'
    },
    error: {
      25: '#FFFBFA',
      50: '#FEF3F2',
      100: '#FEE4E2',
      200: '#FECDCA',
      300: '#FDA29B',
      400: '#F97066',
      500: '#F04438',
      600: '#D92D20',
      700: '#B42318',
      800: '#912018',
      900: '#7A271A'
    },
    success: {
      25: '#F6FEF9',
      50: '#ECFDF3',
      100: '#D1FADF',
      200: '#A6F4C5',
      300: '#6CE9A6',
      400: '#32D583',
      500: '#12B76A',
      600: '#12B76A',
      700: '#027A48',
      800: '#05603A',
      900: '#054F31'
    },
    warning: {
      25: '#FFFCF5',
      50: '#FFFAEB',
      100: '#FEF0C7',
      200: '#FEDF89',
      300: '#FEC84B',
      400: '#FDB022',
      500: '#F79009',
      600: '#DC6803',
      700: '#B54708',
      800: '#93370D',
      900: '#7A2E0E'
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    }
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: '3px 14px'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#F04438',
          fontSize: '1.4rem',
          lineHeight: '2rem',
          margin: '4px 0'
        }
      }
    }
  },
  shape: {
    borderRadius: '8px'
  },
  typography: {
    fontFamily: "'Karla','Roboto', sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '7.2rem',
      lineHeight: ' 9rem'
    },
    h2: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '6rem',
      lineHeight: '7.2rem'
    },
    h3: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '4.8rem',
      lineHeight: '6rem'
    },
    h4: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '3.6rem',
      lineHeight: '4.4rem'
    },
    h5: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '3rem',
      lineHeight: '3.8rem'
    },
    h6: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '2.4rem',
      lineHeight: '3.2rem'
    },
    body1: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '2rem',
      lineHeight: '3rem'
    },
    body2: {
      fontFamily: "'Karla', sans-serif",
      fontSize: ' 1.8rem',
      lineHeight: '2.8rem'
    },
    body3: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '1.6rem',
      lineHeight: '2.4rem'
    },
    body4: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '1.4rem',
      lineHeight: '2rem'
    },
    body5: {
      fontFamily: "'Karla', sans-serif",
      fontSize: '1.2rem',
      lineHeight: '1.8rem'
    },
    button: {
      fontFamily: "'Karla', sans-serif",
      fontWeight: 700,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      color: '#FFFFFF',
      textTransform: 'none',
      minHeight: 44
    }
  }
})
