import api from './api'
import { getHeaders } from './user'

export async function listCustomers(token) {
  const { data } = await api.get('/customer', { ...getHeaders(token) })
  return data
}

export function getFormDataHeaders(token) {
  const headers = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  }
  return headers
}

export async function registerCustomer(token, values) {
  await api.post('/customer', { ...values }, { ...getFormDataHeaders(token) })
}

export async function updateCustomer(token, idCustomer, values) {
  await api.put(
    `/customer/${idCustomer}`,
    { ...values },
    { ...getFormDataHeaders(token) }
  )
}

export async function deleteCustomer(token, idCustomer) {
  await api.delete(`/customer/${idCustomer}`, { ...getHeaders(token) })
}

export async function listCustomersByName(token, nameFilter) {
  const { data } = await api.get(`/customer/name?name=${nameFilter}`, {
    ...getHeaders(token)
  })
  return data
}
