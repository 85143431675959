export const buildQueryParams = (params) => {
  const queryParams = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(encodeURIComponent(key), encodeURIComponent(params[key]));
    }
  }

  return params ? `?${queryParams.toString()}` : '';
}
