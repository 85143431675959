import api from './api'
import { getHeaders } from './user'

export async function listCategories(token) {
  const { data } = await api.get('/category', { ...getHeaders(token) })
  return data
}

export async function registerCategory(token, values) {
  await api.post('/category', { ...values }, { ...getHeaders(token) })
}

export async function updateCategory(token, idCategory, values) {
  await api.put(`/category/${idCategory}`, { ...values }, { ...getHeaders(token) })
}

export async function deleteCategory(token, idCategory) {
  await api.delete(`/category/${idCategory}`, { ...getHeaders(token) })
}

export async function listCategoriesByName(token, nameFilter) {
  const { data } = await api.get(`/category/name?name=${nameFilter}`, {
    ...getHeaders(token)
  })
  return data
}
