import { GlobalStyles as MUIGlobalStyles, css } from '@mui/material'

const CSStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: auto;
    scrollbar-color: #111111 #ffffff;
  }

  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #111111;
    border-radius: 8px;
    border: 2px solid #ffffff;
  }

  html {
    font-size: 62.5%;
  }

  body {
    min-height: 100vh;
  }

  img {
    display: flex;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  label {
    cursor: pointer;
  }

  table,
  td,
  tr {
    caret-color: transparent;
  }
`

const GlobalStyles = () => <MUIGlobalStyles styles={CSStyles} />

export default GlobalStyles
