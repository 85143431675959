import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { theme } from 'theme/index'
import { menuList } from 'utils/menuList'

function useGlobalProvider() {
  const [user, setUser, removeUser] = useLocalStorage('user')
  const [token, setToken, removeToken] = useLocalStorage('token')
  const [openLoading, setOpenLoading] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [stateMenuList, setStateMenuList] = useState([...menuList])
  const upMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const [categories, setCategories] = useState([])
  const [customers, setCustomers] = useState([])
  const [inputRecords, setInputRecords] = useState([])
  const [outputRecords, setOutputRecords] = useState([])
  const [chartData, setChartData] = useState([])
  const [chargeType, setChargeType] = useState('inputs')
  const [filterDate, setFilterDate] = useState({
    days: new Date().getDate(),
    currentMonth: true
  })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filterPage, setFilterPage] = useState({ page, perPage })
  const [selectedFilter, setSelectedFilter] = useState('currentMonth')

  const handleChangeTab = (_, value) => {
    setChargeType(value)
  }

  function toggleMobileMenu() {
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    setPage(1)
  }, [perPage, chargeType, filterDate]);

  useEffect(() => {
    setFilterPage(() => ({
      page: 1,
      perPage,
    }));
  }, [perPage, chargeType]);

  return {
    user,
    setUser,
    removeUser,
    token,
    setToken,
    removeToken,
    openLoading,
    setOpenLoading,
    openMenu,
    setOpenMenu,
    toggleMobileMenu,
    stateMenuList,
    setStateMenuList,
    upMobile,
    categories,
    setCategories,
    customers,
    setCustomers,
    inputRecords,
    setInputRecords,
    outputRecords,
    setOutputRecords,
    chartData,
    setChartData,
    chargeType,
    filterDate,
    setFilterDate,
    page,
    setPage,
    perPage,
    setPerPage,
    filterPage,
    setFilterPage,
    selectedFilter,
    setSelectedFilter,
    handleChangeTab
  }
}

export default useGlobalProvider
